import { ReactElement } from 'react';
import { BoxProps, Box, Text, Flex, GridItem, Grid } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

import PurpleFaDollarSign from '@public/icons/jobs/PurpleFaDollarSign.svg';
import { PropsWithChildren } from 'lib/@types';
import Heading from 'components/atoms/heading';
import { Badge } from 'components/atoms/badge';
import { BadgeTech } from 'components/atoms/badgeTech';
import { formatTechs } from 'lib/utils/jobCard';
import * as paths from 'lib/constants/paths';
import { formatCity } from 'lib/utils/formatCity';
import { CountryFlag, FlagEnum } from 'components/atoms/countryFlag';
import { SalaryRange, SalarySuffixTypes } from 'components/atoms/salaryRange';
import { JobTypeEnum } from 'lib/generated/graphql';

export interface JobCardProps extends BoxProps {
  acceptRemote: boolean;
  hybrid: boolean;
  city: string;
  slug: string;
  techs: Array<{ name: string; urlPath: string }>;
  title: string;
  cltSalaryMin: number;
  cltSalaryMax: number;
  pjSalaryMin: number;
  pjSalaryMax: number;
  usdAnnualSalaryMin: number;
  usdAnnualSalaryMax: number;
  hideSalary: boolean;
  isInternational: boolean;
  jobType: JobTypeEnum;
  companySlug: string;
}

const JobCard = ({
  acceptRemote,
  hybrid,
  city,
  cltSalaryMin,
  cltSalaryMax,
  pjSalaryMin,
  pjSalaryMax,
  usdAnnualSalaryMin,
  usdAnnualSalaryMax,
  slug,
  techs,
  title,
  hideSalary,
  isInternational,
  jobType,
  companySlug,
}: PropsWithChildren<JobCardProps>): ReactElement => (
  <Box _hover={{ boxShadow: 'sm' }}>
    <Link
      href={
        jobType === JobTypeEnum.FullSupport
          ? paths.SLUG_JOB(slug)
          : paths.V2_SLUG_JOB(companySlug, slug)
      }
      passHref
    >
      <Flex
        h='100%'
        flexDirection='column'
        justifyContent='space-between'
        bgColor='white.100'
        cursor='pointer'
        borderRadius={3}
        px={{ base: 3, lg: 7 }}
        py={9}
        w='100%'
      >
        <Box>
          <Heading
            format='h3'
            mt={0}
            pt={0}
            mb={4}
            fontFamily="'Poppins', sans-serif"
            fontWeight={500}
            fontSize='xl'
            lineHeight={7}
            letterSpacing='tight'
            color='black.700'
          >
            {title}
          </Heading>

          {(city || acceptRemote) && (
            <Flex mb={5} flexWrap='wrap' align='center'>
              <Flex mr={2} align='center'>
                <CountryFlag
                  flag={isInternational ? FlagEnum.usFlag : FlagEnum.brFlag}
                  height={16}
                />
                {city && (
                  <Text ml={2.5} fontSize='md' color='black.700'>
                    {formatCity(city)}
                  </Text>
                )}
              </Flex>

              {!isInternational && (acceptRemote || hybrid) && (
                <Badge variant='remote' text={hybrid ? 'híbrido' : 'remoto'} />
              )}

              {isInternational && acceptRemote && (
                <Badge variant='remote' text={city ? 'híbrido' : 'remoto'} />
              )}
            </Flex>
          )}

          {!hideSalary && (
            <Flex flexDirection='column' gridGap={0.5} mb={2.5}>
              {(cltSalaryMin > 0 || cltSalaryMax > 0) && (
                <Grid
                  justifyContent='start'
                  alignItems='center'
                  templateColumns='repeat(3, auto)'
                  columnGap={2.5}
                >
                  <GridItem pt='2px'>
                    <Image
                      src={PurpleFaDollarSign}
                      alt='Dollar sign icon'
                      height='16px'
                      width='16px'
                    />
                  </GridItem>
                  <GridItem gridColumnStart={2}>
                    <SalaryRange
                      minSalary={cltSalaryMin}
                      maxSalary={cltSalaryMax}
                      currency='R$'
                      suffix={SalarySuffixTypes.CLT}
                    />
                  </GridItem>
                  <GridItem>
                    <Badge text='CLT' />
                  </GridItem>
                </Grid>
              )}

              {(pjSalaryMin > 0 || pjSalaryMax > 0) && (
                <Grid
                  justifyContent='start'
                  alignItems='center'
                  templateColumns='repeat(3, auto)'
                  columnGap={2.5}
                >
                  <GridItem pt='2px'>
                    <Image
                      src={PurpleFaDollarSign}
                      alt='Dollar sign icon'
                      height='16px'
                      width='16px'
                    />
                  </GridItem>
                  <GridItem gridColumnStart={[2, 2]}>
                    <SalaryRange
                      minSalary={pjSalaryMin}
                      maxSalary={pjSalaryMax}
                      currency='R$'
                      suffix={SalarySuffixTypes.PJ}
                    />
                  </GridItem>
                  <GridItem>
                    <Badge variant='rawText' text='PJ ou Cooperativa' />
                  </GridItem>
                </Grid>
              )}

              {(usdAnnualSalaryMin > 0 || usdAnnualSalaryMax > 0) && (
                <Grid
                  justifyContent='start'
                  templateColumns='repeat(3, auto)'
                  alignItems='center'
                  columnGap={2.5}
                >
                  <GridItem pt='2px'>
                    <Image
                      src={PurpleFaDollarSign}
                      alt='Dollar sign icon'
                      height='16px'
                      width='16px'
                    />
                  </GridItem>
                  <GridItem gridColumnStart={2}>
                    <SalaryRange
                      minSalary={usdAnnualSalaryMin}
                      maxSalary={usdAnnualSalaryMax}
                      currency='US$'
                      suffix={SalarySuffixTypes.PJ_US}
                    />
                  </GridItem>
                  <GridItem>
                    <Badge variant='rawText' text='PJ ou Cooperativa' />
                  </GridItem>
                </Grid>
              )}
            </Flex>
          )}

          <Flex color='text.100' align='center' flexWrap='wrap' mb={8}>
            {formatTechs(techs).map((tech) => (
              <Link
                key={tech.name}
                href='/vagas-:tech'
                as={tech.urlPath}
                passHref
              >
                <BadgeTech
                  text={tech.name}
                  borderColor='primary.500'
                  hoverBgColor='primary.500'
                  hoverTextColor='white.100'
                  textColor='primary.500'
                  mr={3.5}
                  mb={2}
                />
              </Link>
            ))}
          </Flex>
        </Box>
        <Text
          p={2}
          w='fit-content'
          cursor='pointer'
          color='primary.500'
          fontFamily="'Poppins', sans-serif"
          textDecor='underline'
          fontWeight={400}
          letterSpacing='widest'
          _hover={{
            backgroundColor: 'primary.100',
            borderRadius: 'base',
          }}
        >
          Visualizar vaga
        </Text>
      </Flex>
    </Link>
  </Box>
);

export { JobCard };
