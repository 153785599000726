export const ROOT = '/';

/** EXTERNAL PATHS */

export const INSTAGRAM = `https://www.instagram.com/geekhunterbr/?utm_source=referral&utm_medium=home-site-geek&utm_campaign=home-site-geek`;
export const HELP = 'https://geekhunter.freshdesk.com/support/home';
export const LINKEDIN = 'https://www.linkedin.com/company/geekhunter-brasil';
export const TWITTER = 'https://twitter.com/geekhunterbr';
export const YOUTUBE =
  'https://www.youtube.com/channel/UCkgDe8NO8eQrNlkL8I7Mp_w';

export const CANDIDATE_PROFILE_NEED_HELP =
  'https://suporte.geekhunter.com.br/pt-BR/support/solutions/articles/9000185529-como-melhorar-o-meu-perfil-';

/** PUBLIC PATHS */

export const ABOUT = '/sobre-nos';
export const CANDIDATE_SCREEN = '/candidates';
export const JOBS = '/vagas';
export const LOGIN = '/entrar';
export const LOGOUT = '/logout';
export const TECHS_JOBS = (tech: string): string => `/vagas-${tech}`;
export const POLITICS = '/politicas-de-privacidade';
export const POLITICS_INTERNATIONAL = '/privacy-policy';
export const TERMS = '/termos-de-uso';
export const TERMS_INTERNATIONAL = '/terms-of-use';
export const DATA_PRIVACY = '/data-privacy-policy';
export const DATA_PRIVACY_INTERNATIONAL = '/data-privacy-policy-international';
export const TRAAS = `/tech-recruiter-as-a-service`;
export const SUBSCRIPTION_TERMS = '/termos-de-adesao';

/** JOBS PAGE PATHS */

export const JOBS_JAVA = '/vagas-java';
export const JOBS_JAVASCRIPT = '/vagas-javascript';
export const JOBS_NODEJS = '/vagas-node-js';
export const JOBS_PHP = '/vagas-php';
export const JOBS_PYTHON = '/vagas-python';
export const JOBS_REACT = '/vagas-react';
export const JOB = '/vaga';
export const V2_SLUG_JOB = (companySlug: string, slug: string): string =>
  `/v2/${companySlug}/job/${slug}`;
export const SLUG_JOB = (slug: string): string => `${JOB}/${slug}`;
export const RELATED_JOBS = (id: string): string => `vagas-relacionadas/${id}`;

/** CANDIDATE PATHS */

export const CANDIDATE_BID_DETAILS = (bidId: number): string =>
  `/candidates/geekflow/details/${bidId}`;
export const CANDIDATE_CONFIGS = '/candidates/configuration';
export const CANDIDATE_DASHBOARD = '/candidates/dashboard';
export const CANDIDATE_PREVIEW = '/candidates/preview';
export const CANDIDATE_GEEKFLOW = '/candidates/geekflow';
export const CANDIDATE_REGISTER = '/candidates/register';
export const CANDIDATE_JOBS = '/vagas';
export const CANDIDATE_LP = '/criar-perfil-gratis';
export const CANDIDATE_MESSAGE_BY_ID = (messageId: number): string =>
  `/candidates/messages/${messageId}`;
export const CANDIDATE_MESSAGES = '/candidates/messages';
export const CANDIDATE_PROFILE = '/candidates/preview';
export const CANDIDATE_REQUIRED_TEST = '/candidates/required_test';
export const CANDIDATE_SIGN_IN = '/candidates/sign_in';
export const CANDIDATE_UNANSWERED_BID_DETAILS = (
  messageId: number,
  replyOrigin: string
): string =>
  `/candidates/geekflow/details/${messageId}?reply_origin_bid=${replyOrigin}`;
export const CANDIDATE_VIDEO_ASK = '/candidates/video-ask';

/** COMPANY PATHS */

export const COMPANY_SECURITY_SETTINGS = '/companies/security-settings';
export const COMPANY_CREATE_JOB = '/companies/jobs/new';
export const COMPANY_ACTIVE_JOBS = '/companies/jobs?status=ativas';
export const COMPANY_NEW_JOB = '/companies/jobs/new';
export const COMPANY_ARCHIVED_JOBS = '/companies/jobs?status=arquivadas';
export const COMPANY_BIDS = '/companies/convites?status=aceitos';
export const COMPANY_DASHBOARD = '/companies/dashboard';
export const COMPANY_FAVORITE_CANDIDATES = '/companies/favorite_candidates';
export const COMPANY_HELP =
  'https://geekhunter.freshdesk.com/support/solutions/9000114280';
export const COMPANY_INTERNATIONAL_HELP = 'http://support.geekhunter.com.br/';
export const COMPANY_HIDDEN_CANDIDATES = '/companies/hidden_candidates';
export const COMPANY_INTERVIEW_EVENTS = '/companies/interview_events';
export const COMPANY_LP = '/empresas';
export const COMPANY_PLANS_LP = '/empresas-os-nossos-planos';
export const CHECKOUT_THANK_YOU = '/pagamento-recebido-standard';
export const COMPANY_MESSAGES = '/companies/messages';
export const COMPANY_SUBSCRIPTION = '/companies/subscription';
export const COMPANY_HUB = '/companies/hub';
export const COMPANY_HUB_SALARIES = '/companies/hub/salaries';
export const COMPANY_PIPELINE_CONFIG = (companyId: number): string =>
  `/companies/pipeline_templates/${companyId}/edit`;
export const COMPANY_PROFILE = (companyId: number): string =>
  `/companies/companies/${companyId}`;
export const COMPANY_SEARCH_CANDIDATES = '/companies/candidatos';
export const COMPANY_ACCEPT_TERMS_OF_USE = '/companies/accept-terms-of-use';
export const COMPANY_PAUSED_PACKAGE = '/companies/paused-package';
export const COMPANY_INTEGRATIONS = '/companies/integrations';
export const COMPANY_SIGN_IN = '/company_users/sign_in';
export const COMPANY_USERS_AND_PERMISSIONS = '/companies/company-users/list';
export const COMPANY_SIGNUP_DASHBOARD = (query?: string): string =>
  `/companies/signup/dashboard${query ? `?${query}=true` : ''}`;
export const COMPANY_CANDIDATE_PROFILE = (
  candidateId: number,
  originBid: string,
  searchId: string | undefined = undefined
): string => {
  let uri = `/companies/candidatos/${candidateId}?origin_bid=${originBid}`;

  if (searchId) {
    uri += `&search_id=${searchId}`;
  }

  return uri;
};
export const COMPANY_CANDIDATE_PROFILE_CHAT = (candidateId: number): string => {
  const uri = `/companies/candidatos/${candidateId}/chat/${candidateId}`;

  return uri;
};

export const COMPANY_BID = (bidId: number): string =>
  `/companies/convites/${bidId}`;

/** ADMIN PATHS */

export const CANDIDATE_ADMIN_PROFILE_FRESHCHAT = (
  candidateId: number
): string => `https://www.geekhunter.com.br/admin/candidates/${candidateId}`;
export const COMPANY_ADMIN_PROFILE_FRESHCHAT = (companyId: number): string =>
  `https://www.geekhunter.com.br/admin/companies/${companyId}`;

/** MARKETING PATHS */

export const BLOG_RHTECH = 'https://rhtech.geekhunter.com.br/';
export const BLOG_TECH = 'https://blog.geekhunter.com.br/';
export const GEEK_ACADEMY = `https://www.geekacademy.tech/?utm_source=geekhunter&utm_medium=menu_rodape&utm_campaign=`;

/** DASHBOARD PATHS */
export const DASHBOARD = (userType?: string) => {
  switch (userType) {
    case 'candidate':
      return CANDIDATE_DASHBOARD;
    case 'company':
      return COMPANY_DASHBOARD;
    default:
      return LOGIN;
  }
};
